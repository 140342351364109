





































































import mixins from 'vue-typed-mixins'
import FlexibleGallery from '@/builder/sections/section/shared/mixins/FlexibleGallery'
export default mixins(FlexibleGallery).extend({
  name: 'FlexibleCarousel2',
  data () {
    return {
      carousel: 0,
      carouselItems: new Array<Array<string>>()
    }
  },
  watch: {
    items () {
      this.generatePortfolioImages()
    },
    data () {
      this.generatePortfolioImages()
    },
    isMobile () {
      this.generatePortfolioImages()
    }
  },
  computed: {
    getImageWidth (): string {
      const value = ((100 - this.data.configuration.wb_gallery_distance__number__) / this.numberOfPicturesOnScreen)

      return `${value}%`
    },
    numberOfPicturesOnScreen (): number {
      return this.isMobile
        ? this.data.configuration.wb_gallery_mobile_image_number__number__
        : this.data.configuration.wb_gallery_image_number__number__
    }
  },
  mounted () {
    this.generatePortfolioImages()
  },
  methods: {
    getCardStyle (element: any): Record<string, string> {
      const style: Record<string, string> = {
        margin: `0 ${this.data.configuration.wb_gallery_distance__number__}px`
      }

      const url = this.getImageUrl(element.uuid)
      if (url) {
        style.backgroundImage = `url(${url})`
        style.backgroundSize = 'cover'
      }

      return style
    },
    generatePortfolioImages (): void {
      const n = this.numberOfPicturesOnScreen
      const items = [...this.data.configuration.wb_features__array_section_features__]

      this.carouselItems = new Array(Math.ceil(items.length / n))
        .fill('')
        .map(() => items.splice(0, n))
    }
  }
})
