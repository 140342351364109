import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'

export default mixins(Section).extend({
  computed: {
    getGalleryWidth () {
      if (this.isMobile) {
        return '100%'
      }

      return this.data.configuration.wb_gallery_width__number__
        ? this.data.configuration.wb_gallery_width__number__ + '%'
        : '100%'
    },
    galleryStyle1 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_1
      }

      return false
    },
    galleryStyle2 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_2
      }

      return false
    },
    galleryStyle3 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_3
      }

      return false
    }
  }
})
