























































import mixins from 'vue-typed-mixins'
import FlexibleCarousel2 from '@/builder/sections/section/shared/components/FlexibleCarousel2.vue'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'
import FlexibleGallery from '@/builder/sections/section/shared/mixins/FlexibleGallery'
export default mixins(FlexibleGallery).extend({
  name: 'FlexibleGallery2',
  components: { DefaultButton, FlexibleCarousel2 }
})
